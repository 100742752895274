import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Icon,
} from '@mui/material';
import React, { useCallback } from 'react';
import { CTA, FlexBetween, FlexColumnBetween, FlexColumnGap, FlexStartGap } from './styled/styled';
import InputField from '../common/FormInputs/InputField';
import { ERROR_MSG, LABELS, PATHS, REGEX, THROTTLE_TIME } from '../global-constants';
import DropdownMenu from './FormInputs/DropdownMenu';
import { colorsCode, pxToVw, pxToVh } from './colors';
import { FlexRowGap } from '../components/Dashboard/partials/AllSurveys';
import styled from 'styled-components';
import users from '../assets/img/adduser.svg';
import { useNavigate } from 'react-router';
import { commonThemes } from './themes';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerComponent from './DatePickerComponent';
import PropTypes from 'prop-types';
import { AddUser, checkUserExists, searchClient, UpdateUser } from '../store/actions/Users/actions';
import { resetData } from '../store/reducers/slice/Users';
import { resetSearched } from '../store/reducers/slice/SearchUserClientSlice';
import { resetSaved } from '../store/reducers/slice/ClientSlice';
import AutocompleteInput from '../components/User/Clients/AutocompleteInput';
import { CommonChip, CommonLabel, Img } from './FormInputs/Common';
import CloseIcon from '../assets/img/close.svg';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { debounce } from 'lodash';
import { unwrapResult } from '@reduxjs/toolkit';

const style = {
    fontSize: '2.26vh',
    lineHeight: '3.4vh',
};
const AddUpdate = ({ open, button_name, title, close, setUserParams, userParams, searchTxt, filterTxt }) => {
    const styles = commonThemes();
    const history = useNavigate();
    const dispatchAction = useDispatch();
    const addSixMonth = userParams.valid_till
        ? new Date(userParams.valid_till || moment())
        : new Date(moment().add(6, 'month'));
    const [date, setDate] = React.useState(addSixMonth);
    const [search, setSearch] = React.useState('');
    const [userExists, setUserExists] = React.useState({
        loading: false,
        exists: false,
        message: '',
    });
    const { roles = [] } = useSelector(state => state.permissionReducer);
    const { surveyTemplates = [], clientData = {} } = useSelector(state => state.clientReducer);
    const { userDetails = {} } = useSelector(state => state.userReducer);
    const { data = [], loading: searchLoading = false } = useSelector(state => state.searchData);

    const rolesList = roles?.data?.filtered_records?.length ? roles?.data?.filtered_records : [];
    const templateData = surveyTemplates?.activeRecords?.length ? surveyTemplates.activeRecords : [];
    const [template, setTemplate] = React.useState(templateData);
    const isCstClient = ['CST', 'Client'];
    const isAdminCoach = ['Super Admin', 'Admin', 'Coach'];

    const { loading: userExistsLoading, exists, message } = userExists;

    const errorMessage =
        userParams.email && !REGEX.isValidEmail.test(userParams.email)
            ? ERROR_MSG.VALID_EMAIL
            : userParams.email && exists
            ? message
            : '';

    React.useEffect(() => {
        if (userParams.template_id.length > 1) {
            const templateCopy = cloneDeep(template);
            templateCopy.forEach((item, i) => {
                if (userParams.template_id.includes(item.uuid)) {
                    templateCopy[i].is_selected = true;
                }
            });
            setTemplate(templateCopy);
        }
        setUserParams({
            ...userParams,
            valid_till: date,
        });
    }, []);

    const handleClick = () => {
        history(PATHS.ADD_CLIENT);
    };

    const checkUserFound = useCallback(async searchVal => {
        let response = await dispatchAction(checkUserExists({ search: searchVal }));
        const resp = await unwrapResult(response);
        const { exist } = resp;
        setUserExists({ exists: exist, loading: false, message: ERROR_MSG.USER_EXISTS });
    }, []);

    const debouncedChange = useCallback(
        debounce(searchVal => {
            setUserExists({ exists: false, loading: true, message: '' });
            checkUserFound(searchVal);
        }, THROTTLE_TIME),
        [checkUserFound]
    );

    const handleChange = e => {
        const {
            target: { name, value },
        } = e;
        if (name === 'template_id') {
            const clone = cloneDeep(template);
            clone[0].is_selected = true;
            setTemplate(clone);
        }
        setUserParams({ ...userParams, [name]: name === 'template_id' ? [value] : value });
        if (name === 'email' && REGEX.isValidEmail.test(value)) {
            debouncedChange(value);
        }
    };

    const getRoleName = () => rolesList.find(({ uuid }) => uuid === userParams.role_id)?.name;

    const enableDisable = () => {
        const { name, email, role_id, client_id, template_id, valid_till } = userParams;
        const otherFields =
            !name ||
            !REGEX.name.test(name) ||
            !email ||
            !valid_till ||
            !REGEX.isValidEmail.test(email) ||
            exists ||
            userExistsLoading ||
            moment().isAfter(date);
        if (role_id) {
            if (
                isAdminCoach.includes(getRoleName()) &&
                (!template_id || otherFields || !template.some(({ is_selected }) => is_selected === true))
            ) {
                return true;
            } else if (isCstClient.includes(getRoleName()) && (!client_id || otherFields)) {
                return true;
            }
        } else if (!role_id || otherFields) {
            return true;
        }
        return false;
    };

    const submitHandler = () => {
        const payload = {
            ...userParams,
            valid_till: moment(date).format('YYYY-MM-DD'),
        };
        if (isCstClient.includes(getRoleName())) {
            payload.template_id = '';
        } else {
            payload.client_id = '';
        }
        if (userDetails && Object.keys(userDetails).length) {
            payload.user_id = userDetails?.data?.uuid;
            payload.searchTxt = searchTxt;
            payload.filterTxt = filterTxt;
            payload.template_id = Array.isArray(userParams.template_id)
                ? userParams.template_id
                : [userParams.template_id];
            dispatchAction(UpdateUser(payload));
        } else {
            dispatchAction(AddUser(payload));
        }
        closeAddUserModal();
    };

    const closeAddUserModal = () => {
        close();
        const clone = cloneDeep(template);
        setTemplate(clone.map(item => ({ ...item, is_selected: false })));
        setUserParams({
            name: '',
            email: '',
            role_id: '',
            template_id: '',
            valid_till: '',
        });
        dispatchAction(resetData());
        dispatchAction(resetSearched());
        dispatchAction(resetSaved());
    };

    const handleSearchClient = e => {
        const {
            target: { value },
        } = e;
        setSearch(value);
        if (value.length >= 3) {
            dispatchAction(searchClient({ search: value }));
        }
    };

    const handleAutocomplete = (e, values) => {
        setUserParams({
            ...userParams,
            client_id: values?.uuid || '',
        });
    };

    const getOptionLabelFn = (option, type) => {
        if (type) {
            if (option?.client_name) {
                return option?.client_name;
            } else if (userParams?.client_id) {
                return clientData?.client_name;
            } else {
                return '';
            }
        }
        return '';
    };

    const checkForValidName = name => {
        return name && (name.replace(/\s/g, '').length === 0 || !REGEX.name.test(name));
    };

    const handleMultiple = (e, i) => {
        const {
            target: { checked },
        } = e;
        const copy = cloneDeep(template);
        copy[i].is_selected = checked;
        setUserParams({
            ...userParams,
            template_id: copy.filter(({ is_selected }) => is_selected).map(({ uuid }) => uuid),
        });
        setTemplate(copy);
    };

    const deleteHandler = i => {
        const copy = cloneDeep(template);
        copy[i].is_selected = false;
        setUserParams({
            ...userParams,
            template_id: copy.filter(({ is_selected }) => is_selected).map(({ uuid }) => uuid),
        });
        setTemplate(copy);
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                PaperProps={{
                    sx: {
                        maxWidth: `${pxToVw(580)}vw`,
                        width: `${pxToVw(575)}vw`,
                    },
                }}
            >
                <DialogTitle sx={{ mt: `${pxToVh(20)}vh`, ml: `${pxToVw(20)}vw` }}>
                    <CommonLabel
                        textSize={24}
                        textColor={colorsCode.TextHeading}
                        textWeight={500}
                        lineHeight={36}
                        label={title}
                    />
                </DialogTitle>
                <DialogContent
                    sx={{
                        mt: `${pxToVh(20)}vh`,
                        p: `${pxToVh(40)}vh ${pxToVw(40)}vw`,
                    }}
                >
                    <Icon
                        aria-label="close"
                        onClick={closeAddUserModal}
                        sx={{
                            position: 'absolute',
                            right: `${pxToVw(16)}vw`,
                            top: `${pxToVh(16)}vh`,
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={CloseIcon}
                            alt=""
                            style={{
                                height: `${pxToVh(24)}vh`,
                                width: ` ${pxToVw(24)}vw`,
                            }}
                        />
                    </Icon>
                    <DialogContentText>
                        <FlexColumnBetween gap="4" style={{ width: `${pxToVw(480)}vw` }}>
                            <FlexColumnGap gap="0.5">
                                <Typography component="span" sx={style} fontWeight="400">
                                    {LABELS.ADD_EDIT_USER.NAME}
                                    <span className={styles.required}>*</span>
                                </Typography>
                                <InputField
                                    placeholder="Enter name"
                                    width={pxToVw(485)}
                                    name="name"
                                    val={userParams.name}
                                    onChangeFn={handleChange}
                                    error={checkForValidName(userParams.name) ? true : false}
                                    errorMessage={checkForValidName(userParams.name) ? 'Please enter valid name.' : ''}
                                />
                            </FlexColumnGap>
                            <FlexColumnGap gap="0.5">
                                <Typography component="span" sx={style} fontWeight="400">
                                    {LABELS.ADD_EDIT_USER.EMAIL}
                                    <span className={styles.required}>*</span>
                                </Typography>
                                <InputField
                                    placeholder="Enter Email ID"
                                    width={pxToVw(485)}
                                    name="email"
                                    val={userParams.email}
                                    onChangeFn={handleChange}
                                    inputProps={{
                                        InputProps: {
                                            endAdornment: userExistsLoading ? <CircularProgress size="3.5vh" /> : null,
                                        },
                                    }}
                                    error={errorMessage}
                                    errorMessage={errorMessage}
                                />
                            </FlexColumnGap>
                            <FlexRowGap
                                className={styles.rowClass}
                                style={{
                                    padding: '0vh',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <FlexColumnGap gap="0.5">
                                    <Typography component="span" sx={style} fontWeight="400">
                                        {LABELS.ADD_EDIT_USER.ROLE}
                                        <span className={styles.required}>*</span>
                                    </Typography>
                                    <DropdownMenu
                                        menuItems={rolesList}
                                        onChangeFn={handleChange}
                                        width={pxToVw(180)}
                                        value={userParams.role_id}
                                        name="role_id"
                                        placeholder="Select role type"
                                    />
                                </FlexColumnGap>
                                <FlexColumnGap gap="0.5">
                                    <FlexBetweenStr>
                                        <Typography component="span" sx={style} fontWeight="400">
                                            {isCstClient.includes(getRoleName()) ? (
                                                <React.Fragment>
                                                    {LABELS.ADD_EDIT_USER.CLIENT}
                                                    <span className={styles.required}>*</span>
                                                    {searchLoading ? (
                                                        <CircularProgress
                                                            size={18}
                                                            thickness={5}
                                                            sx={{ ml: 1, mb: 0 }}
                                                        />
                                                    ) : null}
                                                </React.Fragment>
                                            ) : null}
                                            {/* IF ADMIN */}
                                            {isAdminCoach.includes(getRoleName()) ? (
                                                <React.Fragment>
                                                    {LABELS.FILTER_MODAL.SURVEY_TYPE}
                                                    <span className={styles.required}>*</span>
                                                </React.Fragment>
                                            ) : null}
                                        </Typography>
                                        {/* if user is not client and cst */}
                                        {isCstClient.includes(getRoleName()) ? (
                                            <Typography
                                                component="span"
                                                fontWeight="400"
                                                className="create-client"
                                                onClick={handleClick}
                                            >
                                                <FlexStartGap gap={pxToVw(8)} style={{ alignItems: 'center' }}>
                                                    <Img image={users} h={pxToVh(12)} w={pxToVw(12)} alt="" />
                                                    {LABELS.ADD_EDIT_USER.CREATE_CLIENT}
                                                </FlexStartGap>
                                            </Typography>
                                        ) : null}
                                    </FlexBetweenStr>
                                    {/* for admin */}
                                    {isAdminCoach.includes(getRoleName()) ? (
                                        <FlexColumnGap gap={pxToVh(10)}>
                                            <DropdownMenu
                                                menuItems={template.map(item => {
                                                    return { ...item, name: item.template_name };
                                                })}
                                                width={pxToVw(280)}
                                                value={template.length > 1 ? [] : userParams.template_id}
                                                name={`template_id`}
                                                onChangeFn={template.length === 1 ? handleChange : null}
                                                onClickMultiple={template.length > 1 ? handleMultiple : null}
                                                search={search}
                                                placeholder="Select survey template"
                                                multiple={template.length > 1}
                                                isMultiple
                                            />
                                            <FlexStartGap
                                                style={{
                                                    flexWrap: 'wrap',
                                                    gap: `${pxToVh(7)}vh ${pxToVw(1)}vw`,
                                                }}
                                            >
                                                {template.map(({ is_selected, template_name }, i) => {
                                                    // .map(({ template_name }, i) => (
                                                    if (is_selected === true) {
                                                        return (
                                                            <CommonChip
                                                                key={i}
                                                                value={template_name}
                                                                onDeleteHandler={() => deleteHandler(i)}
                                                                canDelete={true}
                                                            />
                                                        );
                                                    }
                                                })}
                                            </FlexStartGap>
                                        </FlexColumnGap>
                                    ) : null}
                                    {/* /for client */}
                                    {isCstClient.includes(getRoleName()) ? (
                                        <AutocompleteInput
                                            data={data}
                                            handleAutocomplete={handleAutocomplete}
                                            value={clientData?.client_name}
                                            width={pxToVw(280)}
                                            getOptionLabelFn={getOptionLabelFn}
                                            handleSearch={handleSearchClient}
                                            name="client_id"
                                        />
                                    ) : null}
                                </FlexColumnGap>
                            </FlexRowGap>
                            <FlexColumnGap gap="0.5">
                                <Typography component="span" sx={style} fontWeight="400">
                                    {LABELS.ADD_EDIT_USER.VALID}
                                    <span className={styles.required}>*</span>
                                </Typography>
                                <DatePickerComponent setDate={setDate} date={date} width={480} />
                            </FlexColumnGap>
                        </FlexColumnBetween>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', gap: `${pxToVw(24)}vw`, mt: `${pxToVw(60)}vh` }}>
                    <CTA
                        pt={8}
                        pl={16}
                        bg={colorsCode.white}
                        color={colorsCode.PrimaryDeepBlue}
                        fw={400}
                        lh={24}
                        size={16}
                        bcolor={colorsCode.PrimaryDeepBlue}
                        onClick={closeAddUserModal}
                    >
                        Cancel
                    </CTA>
                    <CTA
                        pt={8}
                        pl={16}
                        bg={colorsCode.PrimaryDeepBlue}
                        color={colorsCode.white}
                        fw={400}
                        lh={24}
                        size={16}
                        disabled={enableDisable()}
                        onClick={submitHandler}
                    >
                        {button_name}
                    </CTA>
                </DialogActions>
                <br />
            </Dialog>
        </React.Fragment>
    );
};

const FlexBetweenStr = styled(FlexBetween)`
    align-self: stretch;
    padding-bottom: 0;
    img {
        height: 1.7vh;
        width: 0.83vw;
    }
    .create-client {
        font-size: 1.7vh !important;
        color: ${colorsCode.SecondaryDeepBlue500};
        text-transform: uppercase;
        cursor: pointer;
    }
`;

AddUpdate.propTypes = {
    open: PropTypes.bool.isRequired,
    button_name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
};

export default AddUpdate;
